import React, { useState } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import { SubmitButton } from "../components/UI/Buttons"
import ShareBanner from "../components/UI/share-banner"
import NewsLetterForm from "../components/UI/newsletter-form"

const IntroBox = styled.div`
  padding-top: 5em;

  h1 {
    text-align: center;
  }

  h5 {
    margin: 0 auto;
    width: 80%;
  }

  @media (max-width: 768px) {
    padding: 2em 1em;
  }
`

const ListItem = styled.div`
  position: relative;
  margin: 5em 0;
  display: flex;
  overflow: hidden;
  height: 300px;
  filter: var(--tuva-drop-shadow-filter);
  transition: all 500ms;

  :hover {
    transform: translateZ(15px);
    transition: all 200ms;
  }

  .background {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    transition: all 3s ease;

    :hover {
      transform: scale(1.5);
      transition: all 5s ease;
    }
  }

  .content-shape {
    position: absolute;
    top: 0;
    height: 100%;
    background-color: #fff;
    margin-top: -1px;
    padding-bottom: 1px;
    display: block;
    width: 40%;
    min-width: fit-content;
    transition: all 300ms 200ms ease;

    :hover {
      padding: 2px 50px;
      transition: all 300ms 200ms ease;
    }
  }

  .content-block {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 70%;
    padding: 0 2em;

    .black {
      margin: 0 auto 2em auto;
    }

    .logo {
      max-width: fit-content;
    }
  }

  @media (max-width: 768px) {
    margin: 2em 0;

    h1 {
      font-size: 1.5em;
      width: 80%;
    }

    .content-block {
      padding: 0;
      width: unset;
    }
  }
`

const OurWork = ({ data }) => {
  const { title } = data.allFile.edges[0].node.childMarkdownRemark.frontmatter
  const caseStudies = data.allMarkdownRemark.edges
  const html = data.allFile.edges[0].node.childMarkdownRemark.html
  const [list, setList] = useState(8)
  const [endOfList, setEndOfList] = useState(false)

  const listAdd = e => {
    if (list < caseStudies.length) setList(list + 1)
    else {
      setEndOfList(true)
    }
  }

  const ListElement = caseStudies.slice(0, list).map(({ node }, i) => {
    const { logo, image, title, client } = node.frontmatter
    const slug = node.fields.slug
    const isLeft = i % 2 === 1

    // Create list of case studies from CMS. Order by date.
    return (
      <ListItem
        style={{ flexDirection: isLeft ? "row" : "row-reverse" }}
        key={title}
      >
        <GatsbyImage
          className="background"
          image={image.childImageSharp.gatsbyImageData}
          alt={"" + slug + ""}
        />
        <div
          className="content-shape"
          style={
            isLeft
              ? {
                  clipPath: "polygon(0 0, 100% 0, 70% 100%, 0 100%)",
                  left: "0",
                }
              : {
                  clipPath: "polygon(0 0, 100% 0, 100% 100%, 30% 100%)",
                  right: "0",
                }
          }
        >
          <div
            className="content-block"
            style={
              isLeft
                ? { margin: "1em 7em 1em 1em", textAlign: "left" }
                : { margin: "1em 1em 1em 7em", textAlign: "right" }
            }
          >
            <GatsbyImage
              image={logo.childImageSharp.gatsbyImageData}
              imgStyle={{ objectFit: "contain" }}
              alt={client}
              className="logo"
            />
            <h1 className="blog">{title}</h1>
            <Link className="black" to={slug}>
              Find Out More
            </Link>
          </div>
        </div>
      </ListItem>
    )
  })

  return (
    <Layout>
      <IntroBox>
        <h1>{title}</h1>
        <h5
          style={{ whiteSpace: "pre-wrap" }}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </IntroBox>
      <div>{ListElement}</div>
      <div className="call-to-action">
        {endOfList ? (
          <h3 style={{ paddingRight: "1em", margin: "0" }}>
            This is all of our current case studies.
          </h3>
        ) : null}
        <SubmitButton click={listAdd} disabled={endOfList}>
          &nbsp;more...&nbsp;
        </SubmitButton>
      </div>
      <NewsLetterForm />
      <ShareBanner />
    </Layout>
  )
}

export const query = graphql`
  {
    allFile(filter: { name: { eq: "work" } }) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              title
            }
            html
          }
        }
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { cms: { eq: "case" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            cms
            client
            date
            image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [PNG, WEBP, AVIF]
                  layout: FULL_WIDTH
                )
              }
            }
            logo {
              childImageSharp {
                gatsbyImageData
              }
            }
            pin
            hshift
            title
            type
          }
        }
      }
    }
  }
`

export default OurWork
