import React, { useState } from "react"
import { SubmitButton } from "../UI/Buttons"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const NewsLetterForm = () => {
  const [details, setDetails] = useState({
    name: "",
    email: "",
    thanks: false,
  })

  const [thanks, setThanks] = useState(false)

  const [errors, setErrors] = useState({
    errorName: "",
    errorEmail: "",
  })

  const handleChange = e => {
    setDetails({ ...details, [e.target.name]: e.target.value })
    setErrors({ errorName: "", errorEmail: "" })
  }

  function handleValidation() {
    let formIsValid = true
    let name = details.name
    let email = details.email
    let lastAtPos = details.email.lastIndexOf("@")
    let lastDotPos = details.email.lastIndexOf(".")

    if (!name) {
      formIsValid = false
      setErrors({ errorName: "This cannot be empty" })
    }

    if (!email) {
      formIsValid = false
      setErrors({ errorEmail: "This cannot be empty" })
    } else if (
      !(
        lastAtPos < lastDotPos &&
        lastAtPos > 0 &&
        details.email.indexOf("@@") === -1 &&
        lastDotPos > 2 &&
        details.email.length - lastDotPos > 2
      )
    ) {
      formIsValid = false
      setErrors({ errorEmail: "Email is not valid" })
    }

    return formIsValid
  }

  const handleSubmit = e => {
    e.preventDefault()

    if (handleValidation()) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "newsletter", ...details }),
      })
        .then(() => setThanks(!thanks))
        .catch(error => alert(error))
    }
  }

  return (
    <div className="layout-box">
      <h1>if you would like to receive our newsletter</h1>
      {thanks ? (
        <h2>Thanks! You're on the list.</h2>
      ) : (
        <form
          data-netlify="true"
          name="newsletter"
          onSubmit={handleSubmit}
          className="address-box"
        >
          <input type="hidden" name="form-name" value="newsletter" />
          <div className="input-box">
            <input
              type="text"
              name="name"
              placeholder="Name:"
              onChange={handleChange}
            />
            <label>{errors.errorName}</label>
          </div>
          <div className="input-box">
            <input
              type="text"
              name="email"
              placeholder="Email:"
              onChange={handleChange}
            />
            <label>{errors.errorEmail}</label>
          </div>
          <div className="input-box end-grid">
            <SubmitButton>Submit</SubmitButton>
          </div>
        </form>
      )}
    </div>
  )
}

export default NewsLetterForm
